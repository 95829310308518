import { createI18n } from 'vue-i18n';
import enTranslation from './locales/en/translation.json';
import deTranslation from './locales/de/translation.json';

// i18n setup
const messages = {
  en: {
    translation: enTranslation
  },
  de: {
    translation: deTranslation
  }
};

function detectBrowserLanguage() {
  const browserLang = navigator.language || navigator.userLanguage; // Get browser language, e.g., 'en-US' or 'de-DE'
  const shortLang = browserLang.split('-')[0]; // Extract the short form, e.g., 'en' or 'de'

  // Check if the detected language is available in `messages`
  return Object.keys(messages).includes(shortLang) ? shortLang : 'en'; // Fallback to 'en' if not supported
}

const i18n = createI18n({
  locale: localStorage.getItem('language') || detectBrowserLanguage(), // Use stored language or detect from browser
  fallbackLocale: 'en', // Fallback to English if language is unsupported
  messages,
});

export default i18n;
